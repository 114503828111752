import { Button, Stack } from '@mui/material';
import React from 'react'
import { DeleteIconButton } from './DeleteIconButton';

interface AddFileButtonProps {
    buttonText: string;
    handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    handleDelete?: () => void
    isDeleted?: boolean
}
export function AddFileButton(props: AddFileButtonProps) {
  return (
    <Stack direction={"row"} spacing={1}>
        <Button variant='outlined' component="label">
            {props.buttonText}
            <input
                type='file'
                accept="*/*"
                onChange={props.handleFileChange}
                hidden
            />
        
        </Button>

        {
          props.isDeleted !== false && props.handleDelete ? (
            <DeleteIconButton handleDelete={props.handleDelete}/>
          ) : null
        }
    </Stack>
  )
}