import { AppState } from 'index';
import React, { FormEvent, useContext } from 'react'

export function useMidi2WavSection() {
    const state = useContext(AppState);

    const handleChangeSelectedSf = (event: React.ChangeEvent<HTMLInputElement>) => {
        try{
            if (event.target.files) {
                const sfUrl = URL.createObjectURL(event.target.files[0]);
                state.midi2wavSettings.value = {
                    ...state.midi2wavSettings.value,
                    sf_path: sfUrl,
                    sf_name: event.target.files[0].name,
                }
            }
        } catch {

        }
    }

    const handleChangeSelectedMidi = (event: React.ChangeEvent<HTMLInputElement>) => {
        try{
            if (event.target.files) {
                const midiUrl = URL.createObjectURL(event.target.files[0]);
                state.midi2wavSettings.value = {
                    ...state.midi2wavSettings.value,
                    midi_path: midiUrl,
                    midi_name: event.target.files[0].name,
                }
            }
        } catch {

        }
    }

    const handleRenderMidiPack = async () => {

    }

    const handleChangeSeparated = (event: React.ChangeEvent<HTMLInputElement>) => {
        state.midi2wavSettings.value = {
            ...state.midi2wavSettings.value,
            separated: event.target.checked,
        }
    }

    const setError = (error: string) => {
        state.midi2wavSettings.value = {
            ...state.midi2wavSettings.value,
            result_path: '',
            error: error,
        }
    }

  return {
    handleChangeSelectedSf,
    handleChangeSelectedMidi,
    handleRenderMidiPack,
    setError,
    handleChangeSeparated
  }
}
