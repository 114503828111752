import { FormControl, SelectChangeEvent, Stack, Select, MenuItem } from '@mui/material'
import React from 'react'
import { TagStr } from 'types/tags.types'

interface TagSelectProps {
    tagList: readonly TagStr[],
    handleChangeTag: (event: SelectChangeEvent) => void,
    selectedTag: number,
    nameForm: string
}

export function TagSelect(props: TagSelectProps) {
  return (
    <FormControl>
        <Select
            value={props.selectedTag.toString() || ''}
            onChange={props.handleChangeTag}
            >
            {
                props.tagList[0].id === 0 ? null : <MenuItem key={0} value={0}>Не выбран</MenuItem>
            }
            {
                props.tagList.map((tag) => (
                        <MenuItem key={tag.id} value={tag.id}>{tag.name}</MenuItem>
                ))
            }
        </Select>
    </FormControl>
  )
}
