import { SectionForm } from 'components/ui'
import React, { useContext } from 'react'
import { BaseInput } from '../tags/BaseInput'
import { MenuItem, Select } from '@mui/material'
import { AppState } from 'index';
import { usePercussionSettings } from 'hooks/usePercussionSettings';
import { PERCUSSION_PRESETS } from 'utils/constants/presets.const';

export function PercussionSection() {
    const state = useContext(AppState);
    const { handleChangePreset } = usePercussionSettings();
    
  return (
    <SectionForm
        header='Настройка перкуссии'
    >
        <BaseInput name='Preset' idDisabled={false}>

            <Select 
                value={state.selectedPercussion.value.id.toString() || ''}
                onChange={handleChangePreset}
            >
                {
                    PERCUSSION_PRESETS.map((tag) => (
                        <MenuItem key={tag.id} value={tag.id}>{tag.name}</MenuItem>
                    ))
                }
            </Select>
        </BaseInput>

    </SectionForm>
  )
}
