import { Paper, Stack, Typography } from '@mui/material';
import React from 'react'

type SectionProps = {
    children?: React.ReactNode;
    header?: string;
    direction?: "row" | "column";
}
export function SectionForm(
    {children, header, direction}: SectionProps
) {
  return (
    <Paper sx={{p: 2, flex: 1, alignSelf: 'stretch'}}>
      <Stack direction={"column"} spacing={3} justifyContent={"space-between"}>
        {
          header ? (
            <Typography variant="h6">{header}</Typography>
          ) : null
        }
        <Stack direction={direction || "column"} spacing={3} justifyContent={"space-between"}>
          {children}
        </Stack>
      </Stack>
    </Paper>
  )
}