import { computed, signal } from "@preact/signals-react";
import { Midi2WavSettings } from "types";
import { GeneratedNames, GeneratedSynthPath } from "types/result.types";
import { SelectedSf, TagSetSettings, TagStr, TagSynthSettings } from "types/tags.types";
import {BASS_PRESETS_DATA, PERCUSSION_PRESETS, SYNTH_PRESETS_DATA, keys} from "utils/constants/presets.const"

export function createAppState() {
    const isLogin = signal(false);

    const keyTags = signal<TagStr[]>([]);
    const tonalityTags = signal<TagStr[]>([]);
    const instrumentTags = signal<TagStr[]>([]);
    const genreTags = signal<TagStr[]>([]);
    const bass_presets = signal<readonly TagStr[]>(BASS_PRESETS_DATA);

    const synth_presets = signal<readonly TagStr[]>(SYNTH_PRESETS_DATA);
    
    const selectedTagSetSettings = signal<TagSetSettings>({
        // key_id: 0,
        // tonality_id: 0,
        // instrument_id: 0,
        key: keys[0].name,
        bpm: 120,
        genre_id: 0,
        preset: 1
    });

    const selectedTagSynthSettings = signal<TagSynthSettings>({
        preset: 1
    });

    const generatedNames = signal<GeneratedNames>({
        result_id: "",
        midi_files: [],
    });

    const generatedSynthPath = signal<GeneratedSynthPath>({
        result_id: ''
    })

    const generatedBassPath = signal<GeneratedSynthPath>({
        result_id: ''
    })

    const generatedLength = computed(() => {
        return generatedNames.value.midi_files.length;
    })

    const selectedSf = signal<SelectedSf>({
        name: '',
        url_sf: ''
    })

    const selectedPercussion = signal<TagStr>(PERCUSSION_PRESETS[0])
    const midi2wavSettings = signal<Midi2WavSettings>({
        separated: false,
        sf_path: '',
        midi_path: '',
        sf_name: '',
        midi_name: '',
        result_path: '',
        error: '',
    })

    return { 
        isLogin,
        keyTags,
        tonalityTags,
        instrumentTags,
        genreTags,
        bass_presets,
        synth_presets,
        selectedTagSetSettings,
        selectedTagSynthSettings,
        selectedPercussion,
        generatedNames,
        generatedSynthPath,
        generatedBassPath,
        generatedLength,
        selectedSf,
        midi2wavSettings,
    };
}