import { SelectChangeEvent } from '@mui/material'
import { AppState } from 'index'
import React, { useContext } from 'react'
import { PERCUSSION_PRESETS } from 'utils/constants/presets.const'

export function usePercussionSettings() {
    const state = useContext(AppState)

    const handleChangePreset = (e: SelectChangeEvent) => {
        const selTag = PERCUSSION_PRESETS.find(tag => tag.id === Number(e.target.value));
        if (selTag) {
            state.selectedPercussion.value = selTag
        }
    }

    return {
        handleChangePreset,
    }
}
