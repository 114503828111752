import { CheckBox } from '@mui/icons-material';
import { Alert, Button, Checkbox, CircularProgress, FormControlLabel, Stack, Typography } from '@mui/material';
import { renderMidiPack } from 'api/midi2wav.api';
import { AddFileButton } from 'components'
import { SectionForm } from 'components/ui'
import useAuth from 'hooks/useAuth';
import { useMidi2WavSection } from 'hooks/useMidi2WavSection';
import { AppState } from 'index';
import React, { useContext } from 'react'
import { Form } from 'react-router-dom';
import { Midi2WavSettings } from 'types';

export function Midi2WavSection() {
  const state = useContext(AppState);
  const {auth} = useAuth();
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const { handleChangeSelectedMidi, handleChangeSelectedSf, setError, handleChangeSeparated } = useMidi2WavSection();
  

  const handleRenderMidiPack = async () => {
    setError('');
    setIsError(false);
    setIsLoaded(false);

    if (
      state.midi2wavSettings.value.sf_name === '' ||
      state.midi2wavSettings.value.midi_name === ''
    ) {
      setError('Выберите sf и midi файлы');
      setIsError(true);
      return;
    } 

    setIsLoaded(true);
    try {
      const response = await renderMidiPack(state.midi2wavSettings.value, auth);
      if (response === undefined) {
        setError('Undefined response');
        setIsError(true);
        return;
      }

      if (response.error !== '') {
        setError(response.error);
        setIsError(true);
        setIsLoaded(false);
        state.midi2wavSettings.value = response;
      } else {
        state.midi2wavSettings.value = response;
        setIsLoaded(false);
      }
    } catch (error) {
      setIsLoaded(false);
      setError(`Error: ${error}`);
      setIsError(true);
      return;
    }
  }

  const handleDownload = async () => {
    const link = document.createElement('a');
    link.href = state.midi2wavSettings.value.result_path;
    link.setAttribute('download', `${state.midi2wavSettings.value.midi_name}.zip`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    URL.revokeObjectURL(state.midi2wavSettings.value.result_path);

  }

  return (
    <SectionForm
        header="Озвучивание миди пака"
        direction="row"
    >
      <Stack direction={"column"} spacing={1}>
        <AddFileButton
            buttonText="Sf"
            handleFileChange={handleChangeSelectedSf}
            isDeleted={false}
        />
        <Typography variant="caption" color={'primary'}><b>File: {state.midi2wavSettings.value.sf_name}</b></Typography>
      </Stack>

      <Stack direction={"column"} spacing={1}>
        <AddFileButton
            buttonText="Midi"
            handleFileChange={handleChangeSelectedMidi}
            isDeleted={false}
        />
        <Typography variant="caption" color={'primary'}><b>File: {state.midi2wavSettings.value.midi_name}</b></Typography>
      </Stack>

      <FormControlLabel 
        sx={{alignSelf: 'flex-start'}}
        control={
          <Checkbox 
            checked={state.midi2wavSettings.value.separated}
            onChange={handleChangeSeparated}
            inputProps={{ 'aria-label': 'controlled' }}
          />
      } label="Separated"/>

      <Stack direction={"column"} spacing={1}>
        <Stack direction={"row"} spacing={1}>
          <Button
            onClick={handleRenderMidiPack}
            variant='contained'
            disabled={isLoaded}
          >
            {
              isLoaded ? (
                <Stack direction={"row"} spacing={1}>
                  <CircularProgress/>
                  "Loading..."
                </Stack>
              ) : (
                'Озвучить'
              )
            }
          </Button>

          
          <Button
            onClick={handleDownload}
            variant='outlined'
            disabled={state.midi2wavSettings.value.result_path === ''}
          >
            Скачать
          </Button>
        </Stack>

        { 
          isError ? (
            <Alert severity="error">{state.midi2wavSettings.value.error}</Alert>
          ) : null
        }
      </Stack>
    </SectionForm>
  )
}
