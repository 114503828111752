import type { Midi2WavSettings } from "types";
import { errorCatch } from "./error";
import { Auth } from "hooks/useAuth";
import axios, { AxiosResponse } from "axios";

export async function renderMidiPack(midi2wavSettings: Midi2WavSettings, auth: Auth) {
    try {
        const fileSf = await axios.get<Blob>(midi2wavSettings.sf_path, {
            responseType: 'blob',
        })
        const fileSfBlob = fileSf.data;

        const fileMidi = await axios.get<Blob>(midi2wavSettings.midi_path, {
            responseType: 'blob',
        })
        const fileMidiBlob = fileMidi.data;

        const formData = new FormData();
        formData.append('midi_file', fileMidiBlob, midi2wavSettings.midi_name);
        formData.append('sf2_file', fileSfBlob, midi2wavSettings.sf_name);
        formData.append('separated', midi2wavSettings.separated ? 'true' : 'false');

        const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/experiment/render/pack`,
            formData, {
            responseType: 'arraybuffer',
            auth: {
                username: auth.username,
                password: auth.password
            }
        });
        const resUrl = URL.createObjectURL(new Blob([response.data], {type: 'application/zip'}));
        const result: Midi2WavSettings = {
            ...midi2wavSettings,
            result_path: resUrl,
            error: '',
        }
        return result;
        
    } catch (error) {
        const result: Midi2WavSettings = {
            ...midi2wavSettings,
            result_path: '',
            error: errorCatch(error),
        }
        return result;
    }
}